import api from '@/api';
import request from '@/store/modules/utils/request';

export default {
  namespaced: true,
  state: {
    list: [],
    loading: false,
  },
  mutations: {
    SET_NEWS(state, payload) {
      state.list.ru = payload.list || [];
    },
    SET_LOADING(state, { status = false }) {
      state.loading = status;
    },
  },
  actions: {
    getNews(store) {
      const { commit } = store;
      return request(store, {
        url: api.news.getNews,
        method: 'GET',
      })
        .then((data) => {
          commit('SET_NEWS', data);
          return data.success;
        });
    },
    setRules(store, body) {
      return request(store, {
        url: api.rules.setRules,
        body,
      })
        .then((data) => data.success);
    },
  },
  modules: {},
};
