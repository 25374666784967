const API_SERVER = process.env.VUE_APP_API_SERVER || 'https://api.furyferret.com';

export default {
  auth: {
    authentication: `${API_SERVER}/api/auth/authentication`,
    refresh: `${API_SERVER}/api/auth/refresh`,
  },
  users: {
    getUsers: `${API_SERVER}/api/auth/admin/get-users`,
    getUser: `${API_SERVER}/api/auth/admin/get-user`,
    addProduct: `${API_SERVER}/api/auth/admin/add-product`,
    updateProduct: `${API_SERVER}/api/auth/admin/update-product`,
    updateAccount: `${API_SERVER}/api/auth/admin/update-account`,
  },
  rules: {
    getRules: `${API_SERVER}/api/rules/recruitment-tools`,
    setRules: `${API_SERVER}/api/rules/admin/set-rules`,
  },
  news: {
    getNews: `${API_SERVER}/api/news`,
    setRules: `${API_SERVER}/api/rules/admin/set-rules`,
  },
};
