<template>
  <p-sidebar
    v-model:visible="isShowNavigation">
    <p-menu
      :model="items"
      @click="isShowNavigation=false"
      class="navigation-list"
    />
  </p-sidebar>
  <p-button
    label="Menu"
    icon="pi pi-list"
    iconPos="left"
    @click="isShowNavigation=true"
  />
  <router-view/>
</template>

<script>
import PSidebar from 'primevue/sidebar';
import PMenu from 'primevue/menu';

const items = [
  {
    label: 'Users',
    items: [
      {
        label: 'Users',
        icon: 'pi pi-fw pi-users',
        to: { name: 'Users' },
      },
    ],
  },
  {
    label: 'Content',
    items: [
      {
        label: 'News',
        icon: 'pi pi-fw pi-align-left',
        to: { name: 'News' },
      },
      {
        label: 'Rules',
        icon: 'pi pi-fw pi-book',
        to: { name: 'RulesForUser' },
      },
    ],
  },
  {
    label: 'Account',
    items: [
      {
        label: 'Logout',
        to: { name: 'Logout' },
      },
    ],
  },
];

export default {
  name: 'Home',
  data() {
    return {
      isShowNavigation: false,
      items,
    };
  },
  components: {
    PMenu,
    PSidebar,
  },
};
</script>

<style lang="scss">
.navigation-list {
  border: none;
  width: 100%;
}
</style>
