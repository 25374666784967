/* eslint-disable */
import api from '@/api.js';


function getDefaultState() {
  return {
    userData: {},
    isLoading: false,
    errorMessages: {
      userData: '',
      removeUser: '',
    },
  };
}

const state = getDefaultState();

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_LOADING(state, { status }) {
    state.isLoading = status;
  },
  SET_ERROR_MESSAGE(state, { errorMessage, isUserData = false, isRemoveUser = false }) {
    if (isUserData) {
      state.errorMessages.userData = errorMessage;
    }
    if (isRemoveUser) {
      state.errorMessages.removeUser = errorMessage;
    }
  },
  SET_USER_DATA(state, payload) {
    const userData = { ...payload };
    delete userData.status;
    state.userData = userData;
  },
};

/* eslint-disable no-shadow,no-param-reassign */
const actions = {
  resetState({ commit }) {
    commit('RESET_STATE');
  },
  getUser({ commit, dispatch }) {
    commit('SET_ERROR_MESSAGE', { errorMessage: '', isUserData: true });
    commit('SET_LOADING', { status: true });
    return dispatch('fetch', {
      url: api.auth.ccpaGetUser,
      data: { method: 'POST' },
    }, { root: true })
      .then((data) => {
        if (data.errorMessage) {
          commit('SET_ERROR_MESSAGE', { errorMessage: data.errorMessage, isUserData: true });
        }
        commit('SET_USER_DATA', data);
        return data.status === 200;
      })
      .finally(() => {
        commit('SET_LOADING', { status: false });
      });
  },
  removeUser({ commit, dispatch }) {
    commit('SET_ERROR_MESSAGE', { errorMessage: '', isRemoveUser: true });
    commit('SET_LOADING', { status: true });
    return dispatch('fetch', {
      url: api.auth.ccpaRemoveUser,
      data: { method: 'POST' },
    }, { root: true })
      .then((data) => {
        if (data.errorMessage) {
          commit('SET_ERROR_MESSAGE', { errorMessage: data.errorMessage, isRemoveUser: true });
        }
        return data.status === 200;
      })
      .finally(() => {
        commit('SET_LOADING', { status: false });
      });
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
};
