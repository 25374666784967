import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      // Users
      {
        name: 'Users',
        path: '/users',
        component: () => import(/* webpackChunkName: "Users" */ '../views/pages/Users.vue'),
      },
      {
        name: 'EditUser',
        path: '/user/:id',
        component: () => import(/* webpackChunkName: "Users" */ '../views/pages/EditUser.vue'),
      },
      // Rules for user
      {
        name: 'RulesForUser',
        path: '/rules-for-user/',
        component: () => import(/* webpackChunkName: "RulesForUser" */ '../views/pages/Rules.vue'),
      },
      // News
      {
        name: 'News',
        path: '/news/',
        component: () => import(/* webpackChunkName: "RulesForUser" */ '../views/pages/News.vue'),
      },
    ],
  },
  // Authorization
  {
    name: 'Login',
    path: '/login',
    meta: {
      allowForAll: true,
    },
    component() {
      return import(/* webpackChunkName: "Auth" */ '../views/Login.vue');
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      allowForAll: true,
    },
    component: () => import(/* webpackChunkName: "Logout" */ '../views/Logout.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.allowForAll)) {
    return next();
  }

  if (!store.getters['auth/isAuthentication']) {
    return next('/login');
  }
  return next();
});

export default router;
