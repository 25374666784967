import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import jwt from 'jsonwebtoken';
import {
  ADMIN_RULES,
  AUTH_PRODUCTS,
  SUPER_ADMIN,
  ADMIN_NEWS,
} from '@/credentials';
import modules from './modules';

function isAllowPermissions(user, permissions) {
  // eslint-disable-next-line no-restricted-syntax
  for (const permission of [...permissions, SUPER_ADMIN]) {
    if (user.includes(permission)) {
      return true;
    }
  }
  return false;
}

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    credentials: [],
  },
  mutations: {
    SET_CREDENTIALS(state, token) {
      try {
        const data = jwt.decode(token);
        this.state.credentials = data.credentials;
      } catch (e) {
        console.error(new Error(e));
        alert('Error. Check console');
      }
    },
  },
  actions: {},
  getters: {
    isAllowProducts(state) {
      return isAllowPermissions(state.credentials, [AUTH_PRODUCTS]);
    },
    isAllowRules(state) {
      return isAllowPermissions(state.credentials, [ADMIN_RULES]);
    },
    isAllowNews(state) {
      return isAllowPermissions(state.credentials, [ADMIN_NEWS]);
    },
  },
  plugins: [vuexLocal.plugin],
  modules,
});
