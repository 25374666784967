<template>
  <router-view/>
</template>

<script>

</script>

<style lang="scss">
body {
  font-family: var(--font-family);
}

@import '../node_modules/primevue/resources/themes/saga-blue/theme.css';
@import '../node_modules/primevue/resources/primevue.min.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeflex/primeflex.css';
</style>
