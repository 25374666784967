import api from '@/api';
import request from '@/store/modules/utils/request';

export default {
  namespaced: true,
  state: {
    userList: [],
    current: null,
    next: null,
    prev: null,
    total: 0,
    user: {},
    loading: false,
  },
  mutations: {
    SET_USERS(state, users) {
      state.userList = users.list;
      state.current = users.current;
      state.next = users.next;
      state.prev = users.prev;
      state.total = users.total;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_LOADING(state, { status = false }) {
      state.loading = status;
    },
  },
  actions: {
    getUsers(store, {
      limit = 50,
      offset = 0,
      sortAccountCreateOrder = -1,
      searchEmail = '',
    }) {
      const { commit } = store;
      return request(store, {
        url: api.users.getUsers,
        body: {
          limit,
          offset,
          sortAccountCreateOrder,
          searchEmail,
        },
      })
        .then((data) => {
          commit('SET_USERS', data);
          return data.success;
        });
    },
    getUser(store, id) {
      const { commit } = store;
      return request(store, {
        url: api.users.getUser,
        body: { id },
      })
        .then((data) => {
          commit('SET_USER', data);
          return data.success || data.status === 200;
        });
    },
    addProduct(store, { id, name = 'PREMIUM', days = 30 }) {
      return request(store, {
        url: api.users.addProduct,
        body: {
          id,
          name,
          days,
        },
      })
        .then((data) => data.success || data.status === 200);
    },
    updateProduct(store, { id, name = 'PREMIUM', end = Date.now() }) {
      return request(store, {
        url: api.users.updateProduct,
        body: {
          id,
          name,
          end,
        },
      })
        .then((data) => data.success || data.status === 200);
    },
    updateAccount(store, {
      id,
      confirmedEmail,
      availableSessions,
      credentials,
    }) {
      const body = { id };
      if (confirmedEmail) body.confirmedEmail = confirmedEmail;
      if (availableSessions) body.availableSessions = availableSessions;
      if (credentials) body.confirmedEmail = credentials;

      return request(store, {
        url: api.users.updateAccount,
        body,
      })
        .then((data) => data.success || data.status === 200);
    },
  },
  modules: {},
};
