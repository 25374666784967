/* eslint-disable */
import jwt from 'jsonwebtoken';
import sha from 'js-sha512';
import api from '@/api.js';
import { captureException } from '@sentry/browser';

const headers = (() => {
  const AUTH_APP_HEADER = process.env.AUTH_APP_HEADER;
  const result = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  if (AUTH_APP_HEADER) {
    result['App'] = AUTH_APP_HEADER;
  }
  return result;
})();

const { sha512 } = sha;

function fetchForRequest({ payload, link }) {
  return fetch(link, {
    headers,
    referrerPolicy: 'no-referrer-when-downgrade',
    method: 'POST',
    cache: 'no-cache',
    body: payload ? JSON.stringify(payload) : undefined,
  });
}

function request({ commit, payload, link }) {
  commit('SET_LOADING', true);

  return fetchForRequest({
    payload,
    link,
  })
    .then(async response => {
      try {
        return {
          headers: {
            token: response.headers.get('Token'),
            tokenExpiration: response.headers.get('Token-expiration'),
            refresh: response.headers.get('Refresh'),
            refreshExpiration: response.headers.get('Refresh-expiration'),
          },
          response: await response.json(),
        };
      } catch (e) {
        captureException(e);
        console.error(new Error(e));
        return {
          response: {
            success: false,
            errorMessage: 'Something wrong. We know about this error. Just try again or wait',
          },
        };
      }
    })
    .then(({ response, headers }) => {
      commit('SET_AUTH', headers);

      if (response.success === false) {
        commit('SET_ERROR', response);
      } else {
        commit('SET_ERROR', '');
      }
      return response;
    })
    .catch(e => {
      captureException(e);
      console.error(new Error(e));
    })
    .finally(() => {
      commit('SET_LOADING', false);
    });
}

/* eslint-disable no-shadow,no-param-reassign */
const state = {
  id: null,
  token: null,
  tokenExpiration: null,
  refresh: null,
  refreshExpiration: null,
  errorMessage: '',
  isLoading: false,
  products: [],
  needToConfirmEmail: false,
};

const mutations = {
  SET_AUTH(state, payload) {
    let token = null;
    let refresh = null;

    if (payload.token) {
      const { id, products, confirmEmail } = jwt.decode(payload.token);
      state.id = id;
      state.products = products;
      state.needToConfirmEmail = confirmEmail === false;

      token = payload.token;
      refresh = payload.refresh;
    } else {
      state.id = null;
      state.products = null;
      state.needToConfirmEmail = false;
    }

    state.token = token;
    state.tokenExpiration = +payload.tokenExpiration || null;
    state.refresh = refresh;
    state.refreshExpiration = +payload.refreshExpiration || null;
  },
  SET_ERROR(state, { errorMessage = '' }) {
    state.errorMessage = errorMessage;
  },
  SET_LOADING(state, isLoading = false) {
    state.isLoading = isLoading;
  },
};

const actions = {
  authentication({ commit }, payload) {
    const email = payload.login || payload.email;
    const password = sha512(payload.password)
      .toString('hex');

    return request({
      commit,
      payload: {
        email,
        password,
      },
      link: api.auth.authentication,
    });
  },
  registration({ commit }, payload) {
    const email = payload.login || payload.email;
    const password = sha512(payload.password)
      .toString('hex');

    return request({
      commit,
      payload: {
        email,
        password,
      },
      link: api.auth.registration,
    });
  },
  async changePassword({ commit }, payload) {
    const email = payload.login || payload.email;
    const password = sha512(payload.password)
      .toString('hex');
    const newPassword = sha512(payload.newPassword)
      .toString('hex');

    return request({
      commit,
      payload: {
        email,
        password,
        newPassword,
      },
      link: api.auth.changePassword,
    });
  },

  logout({ commit, getters }) {
    const { token, refresh } = getters.getTokens;
    fetchForRequest({
      link: `${api.auth.refresh}?silence=1`,
      payload: {
        token,
        refresh,
      },
    })
      .then();
    commit('SET_AUTH', {});
  },

  resetPassword({ commit }, { email }) {
    commit('SET_LOADING', true);
    return fetchForRequest({
      link: api.auth.resetPassword,
      payload: { email },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success === false) {
          commit('SET_ERROR', response);
        } else {
          commit('SET_ERROR', '');
        }
        return response;
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
};
const getters = {
  userPermissions(state) {
    try {
      if (!getters.isAuthentication(state)) {
        return [0];
      }
      const now = new Date();

      const products = state.products
        .filter(product => new Date(product.end) > now)
        .map(product => {
          try {
            return product.name.toString()
              .replace(process.env.npm_package_version, '');
          } catch (e) {
            captureException(e);
            console.error(new Error(e));
            return product.name;
          }
        });
      return [1, ...products];
    } catch (e) {
      console.error(new Error(e));
      captureException(e);
      return [0];
    }
  },
  isAuthentication(state) {
    return !!(state.id && state.token && state.refresh && state.refreshExpiration > Date.now());
  },
  getTokens(state) {
    if (!state.token || !state.refresh || !state.id) {
      return {
        token: null,
        tokenExpiration: null,
        refresh: null,
        refreshExpiration: null,
      };
    }

    return {
      token: state.token,
      tokenExpiration: state.tokenExpiration,
      refresh: state.refresh,
      refreshExpiration: state.refreshExpiration,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
