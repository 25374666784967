import api from '@/api';
import request from '@/store/modules/utils/request';

export default {
  namespaced: true,
  state: {
    current: {
      ru: '',
      en: '',
      version: 0,
    },
    loading: false,
  },
  mutations: {
    SET_RULES(state, rules) {
      state.current.ru = rules.ru || '';
      state.current.en = rules.en || '';
      state.current.version = rules.version || 0;
    },
    SET_LOADING(state, { status = false }) {
      state.loading = status;
    },
  },
  actions: {
    getRules(store) {
      const { commit } = store;
      return request(store, { url: api.rules.getRules, method: 'GET' })
        .then((data) => {
          commit('SET_RULES', data);
          return data.success;
        });
    },
    setRules(store, body) {
      return request(store, { url: api.rules.setRules, body })
        .then((data) => data.success);
    },
  },
  modules: {},
};
