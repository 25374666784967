import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

const app = createApp(App);

app.component('PButton', Button);

app.use(store);
app.use(router);
app.use(PrimeVue);

app.mount('#app');
