/* eslint-disable */
import router from '@/router';
import api from '@/api';

const PAGE_LOGIN = 'Authentication';

const defaultHeaders = (() => {
  const AUTH_APP_HEADER = process.env.AUTH_APP_HEADER;
  const result = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  if (AUTH_APP_HEADER) {
    result['App'] = AUTH_APP_HEADER;
  }
  return result;
})();

const state = {
  lastError: {
    errorCode: null,
    errorMessage: null,
    status: null,
    success: null,
    timestamp: 0,
  },
};

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_ERROR(state, error) {
    if (state.lastError.errorCode === error.errorCode
      && Date.now() - state.lastError.timestamp < 1000) {
      return;
    }

    state.lastError = {
      ...error,
      timestamp: Date.now(),
    };
  },
};

/* eslint-disable no-shadow,no-param-reassign */
const actions = {
  async fetch({ rootGetters, commit, dispatch }, {
    url,
    data: { body, headers = {}, method = 'POST' },
  }) {
    const { token, refresh } = rootGetters['auth/getTokens'];
    return fetch(url, {
      headers: {
        ...defaultHeaders,
        'cache-control': 'no-cache',
        'Token': token,
        'Refresh': refresh,
        ...headers,
      },
      method,
      body: body ? JSON.stringify(body) : undefined,
    })
      .then((response) => {
        const token = response.headers.get('Token');
        const tokenExpiration = response.headers.get('Token-expiration');
        const refresh = response.headers.get('Refresh');
        const refreshExpiration = response.headers.get('Refresh-expiration');
        if (token && refresh) {
          commit('auth/SET_AUTH', {
            token,
            tokenExpiration,
            refresh,
            refreshExpiration,
          }, { root: true });
        }

        return response.json();
      })
      .then((json) => {
        if (json.status === 401) {
          dispatch('auth/logout', null, { root: true });
          router.push({ name: PAGE_LOGIN });
        } else if (!json.success && json.status !== 200) {
          commit('SET_ERROR', json);
        }

        return json;
      });
  },
  refreshToken({ dispatch }) {
    dispatch('fetch', { url: api.auth.refresh, data: { body: {} } });
  },
};

export default {
  // global
  namespaced: false,
  mutations,
  state,
  actions,
};
