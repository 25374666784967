export default function request({ commit, dispatch }, { url, body = {}, method = 'POST' }) {
  commit('SET_LOADING', { status: true });
  return dispatch('fetch', {
    url,
    data: {
      method,
      body: method === 'GET' ? undefined : body,
    },
  }, { root: true })
    .finally(() => {
      commit('SET_LOADING', { status: false });
    });
}
